import axios from 'axios';
import {baseUrl} from '@/js/util.js'

// 获专业
export const F_search_majorList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Home/F_search_majorList`,
        params:datas,
        headers: {'isFrontEnt':true},
    })
}
// 获岗位
export const F_search_jobList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Home/F_search_jobList`,
        params:datas,
        headers: {'isFrontEnt':true},
    })
}
// 获取组合课程列表
export const F_Get_combinationCoursePageList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Home/F_Get_combinationCoursePageList`,
        params:datas,
        headers: {'isFrontEnt':true},
    })
}
// 获取组合课程列表详情
export const F_Get_combinationCourseData=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Home/F_Get_combinationCourseData`,
        params:datas,
        headers: {'isFrontEnt':true},
    })
}

// 删除课程封面
export const DeleteFiles=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Training_API/api/UploadFile/DeleteFiles`,
        data:datas,
    })
}
// 上传课程附件
export const f_insert_courseWareFile=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Training_API/api/F_teacherUser/f_insert_courseWareFile`,
        data:datas,
    })
}
// 获专业下拉
export const get_select_majorList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Dictionary/get_select_majorList`,
        params:datas,
        headers: {'isFrontEnt':true},
    })
}
// 获课程科目下拉
export const get_select_subjectList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Dictionary/get_select_subjectList`,
        params:datas,
        headers: {'isFrontEnt':true},
    })
}
// 添加/修改课程
export const f_update_course=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Training_API/api/F_teacherUser/f_update_course`,
        data:datas,
    })
}
// 添加/修改章
export const f_update_courseChapter=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Training_API/api/F_teacherUser/f_update_courseChapter`,
        data:datas,
    })
}
// 获课程内的章节列表
export const f_get_courseCatalogue=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/F_teacherUser/f_get_courseCatalogue`,
        params:datas,
        headers: {'isFrontEnt':true},
    })
}
// 删除章
export const f_delete_courseCatalogue=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Training_API/api/F_teacherUser/f_delete_courseCatalogue`,
        data:datas,
        headers:{
            "Content-Type":"application/json"
        }
    })
}
// 添加/修改节
export const f_update_courseSection=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Training_API/api/F_teacherUser/f_update_courseSection`,
        data:datas,
    })
}
// 添加教师团队
export const f_update_courseTeacherTeam=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Training_API/api/F_teacherUser/f_update_courseTeacherTeam`,
        data:datas,
    })
}
// 获课程内的教师团队
export const f_get_courseTeacherTeamList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Home/f_get_courseTeacherTeamList`,
        params:datas,
        headers: {'isFrontEnt':true},
    })
}
// 获课精品案例
export const get_select_caseSharing=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Dictionary/get_select_caseSharing`,
        params:datas,
        headers: {'isFrontEnt':true},
    })
}

// 获课精品案例展示内容多选
export const get_select_csColumn=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Dictionary/get_select_csColumn`,
        params:datas,
        headers: {'isFrontEnt':true},
    })
}


// 获课精品案例思政亮点
export const get_select_csGreatCountryCraftsman=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Dictionary/get_select_csGreatCountryCraftsman`,
        params:datas,
        headers: {'isFrontEnt':true},
    })
}


// 获课精品案例专业讲台
export const get_select_csMajorClassRoom=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Dictionary/get_select_csMajorClassRoom`,
        params:datas,
        headers: {'isFrontEnt':true},
    })
}


//新增思政课程
export const f_update_courseIdeologyPolitics=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Training_API/api/F_teacherUser/f_update_courseIdeologyPolitics`,
        data:datas,
    })
}

// 获取课程实训的详情
export const f_get_digitalTrainingData=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/F_teacherUser/f_get_digitalTrainingData`,
        params:datas,
        headers: {'isFrontEnt':true},
    })
}
// 获取课程实训的习题详情
export const f_get_trainingExercisesData=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/F_teacherUser/f_get_trainingExercisesData`,
        params:datas,
        headers: {'isFrontEnt':true},
    })
}
// 修改用户头像
export const f_update_userPic=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Training_API/api/F_user/f_update_userPic`,
        data:datas,
    })
}
// 获取课程的课程思政
export const f_get_courseIdeologyPolitics=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/F_teacherUser/f_get_courseIdeologyPolitics`,
        params:datas,
        headers: {'isFrontEnt':true},
    })
}