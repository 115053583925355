<template>
    <div id="courseTeaching">
        <!-- <div style="position: fixed;right: 5%;top: 50%;z-index: 6;">
                    <el-button type="primary" round style="background-color: #76C8F6;border: none;" @click="blackClick">返回</el-button>
                    <el-button type="primary" round style="background-color: #76C8F6;border: none;" >保存</el-button>
                    <el-button type="primary" round style="background-color: #76C8F6;border: none;">发布</el-button>
           </div> -->
        <!-- 悬浮按钮 -->
        <div style="position:fixed;right:10%;bottom:50%;">
            <div @click="blackClick"
                style="cursor:pointer;width: 74px;height: 32px;background: #76C8F6;border-radius: 4px;display: flex;justify-content: space-evenly;align-items: center;margin-bottom: 15px;">
                <img :src="require('@/assets/img/ConstructionDetail/back.png')" style="width:13px; height:15px;" alt="">
                <span style="font-size: 14px;color:#fff;">返回</span>
            </div>
            <div @click="cligocenterCUN()"
                style="cursor:pointer;width: 74px;height: 32px;background: #76C8F6;border-radius: 4px;display: flex;justify-content: space-evenly;align-items: center;margin-bottom: 15px;">
                <img :src="require('@/assets/img/ConstructionDetail/baocun.png')" style="width:13px; height:15px;" alt="">
                <span style="font-size: 14px;color:#fff;">保存</span>
            </div>
            <!-- <div @click="cligocenterFA()"
                style="cursor:pointer;width: 74px;height: 32px;background: #76C8F6;border-radius: 4px;display: flex;justify-content: space-evenly;align-items: center;margin-bottom: 15px;">
                <img :src="require('@/assets/img/ConstructionDetail/fabu.png')" style="width:13px; height:15px;" alt="">
                <span style="font-size: 14px;color:#fff;">发布</span>
            </div> -->
        </div>
        <div class="box">
            <div class="boxOne">
                <el-upload v-if="!imgUrl" class="upload-demo"
                            :action="baseUrl+'/Training_API/api/UploadFile/MultiFileUpload?fileType=1'"
                            :on-success="handleAvatarSuccess" accept=".jpg,.jpeg,.png"
                            :headers="headers" :show-file-list="false" :limit="1">
                            <div
                                style="width: 400px;height: 281px;display: flex;flex-direction: column;align-items: center;justify-content: center;">
                                <span style="display:flex;align-items:center;font-size:50px;color:#666;">+</span>
                                <div class="el-upload__tip" style="">
                                    <div style="font-size: 12px;color:#333;">
                                        点击课程实训封面图
                                    </div>
                                    <div style="font-size: 12px;color:#666;">
                                        图片不超2M，推荐尺寸836px*627
                                    </div>
                                </div>
                            </div>

                        </el-upload>
                        <div style="width: 500px;height: 281px;position: relative;" v-else>
                            <img style="width: 100%;height: 100%;" :src="baseUrl + '/' + imgUrl" alt="" srcset="">
                            <span style="position: absolute;top: 88%;right:2% ;z-index: 99;cursor: pointer;color: #76C8F6;"
                                @click="deleteClickImg">删除</span>
                        </div>
                <div style="margin-left: 10px;width:100%">
                    <el-form :inline="true" :label-position="labelPosition" label-width="120px" ref="formLabel" :model="formLabelAlign" :rules="formLabelAlignrules"
                        size="small">
                        <el-form-item label="所属阶段：" prop="stageValue">
                            <el-select v-model="formLabelAlign.stageValue" class="m-2" placeholder="请选择" size="small">
                                <el-option v-for="item in stageData" :key="item.id" :label="item.stageName"
                                    :value="item.id" />
                            </el-select>
                        </el-form-item>
                        <el-form-item label="课程实训名称:" prop="digitalTraining">
                            <el-input v-model="formLabelAlign.digitalTraining" size="small" style="width: 200px;" placeholder="请输入课程实训名称" />
                        </el-form-item>
                        <el-form-item label="系统发布时间：" prop="releaseDate" label-width="140px">
                            <el-date-picker v-model="formLabelAlign.releaseDate" type="date" format="YYYY-MM-DD"
                                        value-format="YYYY-MM-DD" placeholder="请选择" size="small" />
                        </el-form-item>
                        <el-form-item label="版权所有人或单位：" label-width="150px" prop="copyright">
                            <el-input v-model="formLabelAlign.copyright" size="small" style="width: 200px;" placeholder="请输入版权所有人或单位" />
                        </el-form-item>
                        <el-form-item label="适用专业：" prop="applicableMajors">
                            <el-select v-model="formLabelAlign.applicableMajors" class="m-2" placeholder="请选择" size="small">
                                <el-option v-for="item in majorData" :key="item.id" :label="item.majorName"
                                    :value="item.id" />
                            </el-select>
                        </el-form-item>
                        <el-form-item label="适用行业：" prop="Applications">
                            <el-select v-model="formLabelAlign.Applications" class="m-2" placeholder="请选择" size="small">
                                <el-option v-for="item in industryData" :key="item.id" :label="item.industryName"
                                    :value="item.id" />
                            </el-select>
                        </el-form-item>

                    </el-form>



                </div>
            </div>
            <div class="boxTwo">
                <p
                    style="font-size: 18px;font-weight: 600;border-bottom: 1px solid #F7F8FA;height: 40px;line-height: 40px;padding-left: 20px;">
                    实训配置</p>
                <el-form :inline="true"  class="demo-form-inline"
                    style="margin-left: 20px;margin-top: 20px;">
                    <div v-for="(item, index) in formInline.domains" :key="index">
                        <el-form-item :label="'第' + (index + 1) + '节'">
                            <el-input v-model="item.joint" style="width: 500px;" size="small" placeholder="请输入" />
                        </el-form-item>
                        <el-button type="primary" plain size="mini" @click="peizhiClick(index,item.id)">{{item.exercisesnName?'已配置习题':'配置习题'}}</el-button>
                        <el-button type="primary" plain size="mini" @click="guanlian(index)">{{item.associatedSystemValue?'已关联系统':'关联系统'}}</el-button>
                        <!-- <el-button type="primary" plain  size="mini" @click="kecheng">关联课程</el-button> -->
                        <el-button type="primary" plain size="mini" @click="childrenShowClick(index)">{{item.children[0].childrenShow?'收起描述':'展开描述'}}</el-button>
                        <span type="primary" style="cursor: pointer;margin-left:10px" @click="deleteClick(index)">X</span>
                        <div style="margin-left: 50px;margin-bottom: 10px;" v-for="(item2, index) in item.children"
                            :key="index" v-show="item2.childrenShow">
                            <p style="font-size: 14px;padding-bottom: 10px;padding-top:10px">实训任务：（实训目标+实训知识点）</p>
                            <el-input v-model="item2.textarea2" :autosize="{ minRows: 4, maxRows: 4 }" type="textarea"
                                placeholder="请输入" />
                            <p style="font-size: 14px;padding-bottom: 10px;padding-top:10px">实训步骤：（结合任务，系统操作流程）</p>
                            <el-input v-model="item2.textarea3" :autosize="{ minRows: 4, maxRows: 4 }" type="textarea"
                                placeholder="请输入" />
                            <p style="font-size: 14px;padding-bottom: 10px;padding-top:10px">实训价值：（可以解决什么问题）</p>
                            <el-input v-model="item2.textarea4" :autosize="{ minRows: 4, maxRows: 4 }" type="textarea"
                                placeholder="请输入" />
                        </div>

                    </div>

                    <el-button type="primary" plain style="margin: 20px;" size="mini" @click="addModel">添加节</el-button>
                </el-form>
            </div>
        </div>
        <!-- 配置习题 -->
        <el-dialog v-model="dialogVisible" v-if="dialogVisible" title="配置习题" width="75%" :before-close="handleClose">
            <el-form :inline="true" :model="formInline" class="demo-form-inline" style="margin-left: 20px;" ref="rulesexercisesRef" :rules='rulesexercises'>
                <el-form-item label="习题名称" :prop="'domains.'+domainsIndex+'.exercisesnName'" :rules="rulesexercises.exercisesnName">
                    <el-input  size="mini" v-model="formInline.domains[domainsIndex].exercisesnName" placeholder="请填写习题名称" clearable />
                </el-form-item>
                <div v-for="(item,index) in formInline.domains[domainsIndex].domains2" :key="index">
                <el-form-item label="系统名称" :prop="'domains.'+domainsIndex+'.domains2.'+index+'.system'" :rules="rulesexercises.system">
                    <el-select
                        size="mini"
                        @change="systemChange(index)"
                        v-model="item.system"
                        placeholder="请选择系统名称"
                        clearable>
                        <el-option v-for="item in systemOption" :key="item.id" :label="item.systemName" :disabled="item.status == 1?false:true"
                                    :value="item.id" />
                    </el-select>
                </el-form-item>
                <el-form-item label="模块名称" :prop="'domains.'+domainsIndex+'.domains2.'+index+'.module'" :rules="rulesexercises.module">
                    <el-select
                    @change="moduleChange(index)"
                        size="mini"
                        v-model="item.module"
                        @visible-change="visibleChange(index)"
                        placeholder="请选择模块名称"
                        clearable>
                        <el-option v-for="item in moduleOption" :key="item.id" :label="item.moduleName"
                                    :value="item.id" />
                    </el-select>
                </el-form-item>
                <el-form-item label="选择题型" :prop="'domains.'+domainsIndex+'.domains2.'+index+'.tixing'" :rules="rulesexercises.tixing">
                    <el-select
                    @change="tixingChange(index)"
                    size="mini"
                        v-model="item.tixing"
                        placeholder="请选择题型"
                        clearable>
                        <el-option
                        v-for="item in tixingOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="选择题库难度" :prop="'domains.'+domainsIndex+'.domains2.'+index+'.nandu'" :rules="rulesexercises.nandu">
                    <el-select
                    @change="nanduChange(index)"
                    size="mini"
                        v-model="item.nandu"
                        placeholder="请选择题库难度"
                        clearable>
                        <el-option
                        v-for="item in nanduOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="">当前题库符合条件试题数：{{ item.numTest }}</el-form-item>
                <el-form-item label="填写试题数量" :prop="'domains.'+domainsIndex+'.domains2.'+index+'.stNum'" :rules="rulesexercises.stNum">
                    <el-input  size="mini" v-model="item.stNum" placeholder="请填写试题数量" clearable @input="stNumInput(item)" />
                </el-form-item>
                <span type="primary"  style="cursor: pointer;" @click="delete2Click(index)">X</span>
                </div>

                <el-button type="primary" round style="background-color: #76C8F6;border: none;" @click="addFrom">新增</el-button>
            </el-form>
         
            <p style="font-weight: 600;padding: 20px;">配置规则</p>
            <el-radio-group v-model="formInline.domains[domainsIndex].radio1" class="ml-4" style="margin-left: 20px;" @change="radioChange">
                <el-radio label="1" size="large">随机组卷</el-radio>
                <el-radio label="2" size="large">统一试卷</el-radio>
            </el-radio-group>
            <p v-if="formInline.domains[domainsIndex].radio1 == 1" style="color: red;font-size: 12px;padding-left: 20px;padding-top: 10px;">*随机组卷为基于配置规则及试题数量，随机抽取试题，每个学生的试卷不完全一样</p>
            <p v-else style="color: red;font-size: 12px;padding-left: 20px;padding-top: 10px;">*统一试卷为系统基于配置规则，生成试卷，可在预览后进行确认，每个学生的试卷完全一样</p>
            <el-button  v-if="formInline.domains[domainsIndex].radio1 == 2" style="margin: 20px;" type="primary" plain @click="yulanClick" size="mini">试卷预览</el-button>
            <template #footer>
            <div class="dialog-footer" style="text-align: center;">
                <el-button  @click="dialogVisible = false">取消</el-button>
                <el-button style="background-color: #76C8F6;border: none;" type="primary" @click="insterClick">
                确定
                </el-button>
            </div>
            </template>
        </el-dialog>
        <!-- 试卷预览 -->
        <el-dialog
            v-model="examPreview"
            title="试卷预览"
            width="75%"
            :show-close="false"
            :before-close="examPreviewClose">
            <div class="system">
               <p>
                <span style="padding-right: 20px;">类型:实训习题</span>
                <span style="padding-right: 20px;">系统名称:{{ systemName?systemName:'--' }}</span>
                <span style="padding-right: 20px;">试题名称:{{ formInline.domains[domainsIndex].exercisesnName?formInline.domains[domainsIndex].exercisesnName:'--' }}</span>
               </p>
               <div style="width: 100%;height: 700px;overflow-y: auto;">
                   <p style="font-weight: 600;" v-if="tiankongList.length>0">填空题</p>
                   <div style="margin-top: 10px;background-color: #fff;padding: 10px;" v-for="(item,index) in tiankongList" :key="index+'index'">
                     <div v-if="item.topicType == 3">
                         <div style="margin-top: 10px;font-size: 13px;">
                             <p style="margin-bottom: 5px;"><span style="display: inline-block;background-color: #76C8F6 ;padding-left: 5px;padding-right: 5px;color: #fff;border-radius: 3px;">{{ index+1 }}</span> {{ item.testQuestionContent }}  
                                 <el-button
                                     style="border-color: #76c8f6; color: #76c8f6;margin-top: 10px;margin-left:10px ;"
                                     size="mini"
                                     @click="tiankongQuestions(item,index)"
                                     >替换试题</el-button>
                                 </p>
                         </div>
                         
                     </div>
                   </div>
                  
                  
                  
                   <p style="font-weight: 600;padding-top: 10px;" v-if="danxuanList.length>0">单选题</p>
                   <div style="margin-top: 10px;background-color: #fff;padding: 10px;" v-for="(danxuan,indexdx) in danxuanList" :key="indexdx+'indexdx'">
                     <div v-if="danxuan.topicType == 1">
                         <div style="margin-top: 10px;font-size: 13px;">
                             <p style="margin-bottom: 5px;"><span style="display: inline-block;background-color: #76C8F6 ;padding-left: 5px;padding-right: 5px;color: #fff;border-radius: 3px;">{{ indexdx+1 }}</span> {{ danxuan.testQuestionContent }}  
                                 <el-button
                                     style="border-color: #76c8f6; color: #76c8f6;margin-top: 10px;margin-left:10px ;"
                                     size="mini"
                                     @click="duanxuanQuestions(danxuan,indexdx)"
                                     >替换试题</el-button>
                                 </p>
                             <el-radio-group v-model="isAnswer">
                                 <el-radio  v-for="(item1,index1) in danxuan.answers" :key="index1">{{item1.isOrder == 1?'A':item1.isOrder == 2?'B':item1.isOrder == 3?'C':item1.isOrder == 4?'D':''}} {{ item1.content }}</el-radio>
                             </el-radio-group>
                         </div>
                         
                     </div>
                   </div>
                  

                   <p style="font-weight: 600;padding-top: 10px;" v-if="duoxuanList.length>0">多选题</p>
                   <div style="margin-top: 10px;background-color: #fff;padding: 10px;" v-for="(duoxuan,indexduoxuan) in duoxuanList" :key="indexduoxuan+'indexduoxuan'">
                     <div v-if="duoxuan.topicType == 2">
                         <div style="margin-top: 10px;font-size: 13px;">
                             <p style="margin-bottom: 5px;"><span style="display: inline-block;background-color: #76C8F6 ;padding-left: 5px;padding-right: 5px;color: #fff;border-radius: 3px;">{{ indexduoxuan+1 }}</span> {{ duoxuan.testQuestionContent }}  
                                 <el-button
                                     style="border-color: #76c8f6; color: #76c8f6;margin-top: 10px;margin-left:10px ;"
                                     size="mini"
                                     @click="duoxuanQuestions(duoxuan,indexduoxuan)"
                                     >替换试题</el-button>
                                 </p>
                                 <el-radio-group v-model="isAnswer">
                                     <el-radio  v-for="(item3,index4) in duoxuan.answers" :key="index4+'index4'" >{{item3.isOrder == 1?'A':item3.isOrder == 2?'B':item3.isOrder == 3?'C':item3.isOrder == 4?'D':''}} {{ item3.content }}</el-radio>
                                 </el-radio-group>
                             
                         </div>
                         
                     </div>
                   </div>


                   <p style="font-weight: 600;padding-top: 10px;" v-if="panduanList.length>0">判断题</p>
                   <div style="margin-top: 10px;background-color: #fff;padding: 10px;" v-for="(itempanduan,indexpanduan) in panduanList" :key="indexpanduan+'indexindexpanduan'">
                     <div v-if="itempanduan.topicType == 4">
                         <div style="margin-top: 10px;font-size: 13px;">
                             <p style="margin-bottom: 5px;"><span style="display: inline-block;background-color: #76C8F6 ;padding-left: 5px;padding-right: 5px;color: #fff;border-radius: 3px;">{{ indexpanduan+1 }}</span> {{ itempanduan.testQuestionContent }}  
                                 <el-button
                                     style="border-color: #76c8f6; color: #76c8f6;margin-top: 10px;margin-left:10px ;"
                                     size="mini"
                                     @click="panduanQuestions(itempanduan,indexpanduan)"
                                     >替换试题</el-button>
                                 </p>
                             <el-radio-group v-model="isAnswer">
                                 <el-radio  v-for="(item5,index2) in itempanduan.answers" :key="index2" >{{item5.isOrder == 1?'A':item5.isOrder == 2?'B':item5.isOrder == 3?'C':item5.isOrder == 4?'D':''}} {{ item5.content }}</el-radio>
                             </el-radio-group>
                         </div>
                         
                     </div>
                   </div>
               </div>
    
               <div style="text-align: center;" class="dialog-footer">
                 
                   <el-button type="primary" style="background-color: #76C8F6;border: none;margin-bottom: 20px;" round @click="confirmClick">
                   确认试题
                   </el-button>
               </div>
            
               
           </div>
        </el-dialog>
        <!-- 替换试题 -->
        <el-dialog
        title="替换试题"
        v-model="replaceShow" width="50%"  
        :before-close="replaceShowClose">
         <div class="searchList">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="系统名称">
                    <el-select
                        size="mini"
                        @change="systemChange2(index)"
                        v-model="xitongValue"
                        placeholder="请选择系统名称"
                        clearable>
                        <el-option v-for="item in systemOption" :key="item.id" :label="item.systemName" :disabled="item.status == 1?false:true"
                                    :value="item.id" />
                    </el-select>
                </el-form-item>
                <el-form-item label="模块名称">
                    <el-select
                        size="mini"
                        v-model="moduleValue"
                        placeholder="请选择模块名称"
                        clearable>
                        <el-option v-for="item in moduleOption" :key="item.id" :label="item.moduleName"
                                    :value="item.id" />
                    </el-select>
                </el-form-item>

                <el-form-item label="选择题库难度">
                    <el-select
                    size="mini"
                        v-model="nanduValue"
                        placeholder="请选择题库难度"
                        clearable>
                        <el-option
                        v-for="item in nanduOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        />
                    </el-select>
                </el-form-item>
              <el-form-item label="上传人:">
                <el-input size="mini" v-model="upDataName" placeholder="请输入上传人" clearable />
              </el-form-item>
              <el-form-item label="题干:">
                <el-input size="mini" v-model="stemData" placeholder="请输入题干" clearable />
              </el-form-item>
              <el-form-item>
                <el-button size="small" type="primary"  @click="onSubmit">搜索</el-button>
              </el-form-item>
             
            
            </el-form>
            <div class="searchButton">
                <!-- <el-button type="primary" round style="background-color: #76C8F6;border: none;float:right;margin-top:30px;margin-right:20px;">添加题库</el-button>
                <el-button type="primary" round style="background-color: #76C8F6;border: none;float:right;margin-top:30px;margin-right:20px;">添加题库</el-button> -->

            </div>
          </div>
          <el-table :data="tableData" stripe style="width: 100%;height:500px" max-height="394px">
            <el-table-column fixed type="index"  label="序号" />

            <el-table-column prop="systemName" label="系统名称"  />
            <el-table-column prop="moduleName" label="模块名称" />
    
            <el-table-column prop="chapters" label="难度"  width="110" >
              <template #default="scope">
            <span type="text">{{ scope.row.topicDifficulty == 1?'简单':scope.row.topicDifficulty == 2?'中等':'困难' }}</span>
          </template>
            </el-table-column>
            <el-table-column prop="testQuestionContent" label="题干" width="150" />
            <el-table-column prop="correctAnswer" label="正确答案" width="150" />
            <el-table-column prop="testQuestionAnalysis" label="解析" width="150" />
            <el-table-column prop="insertTime" label="上传时间" width="200" />
            <el-table-column prop="realName" label="上传人" />
            <el-table-column  label="操作" fixed="right" width="160" >
              <template #default="scope">
                  <el-button type="text" @click="deselect(scope.row)" v-if="testQuestionId != scope.row.oldId && oldId != scope.row.oldId">{{scope.row.select == 1?'已选择':'选择'}}</el-button>
                  <span v-else>已选择</span>
              </template>
            </el-table-column>
          </el-table>
          <div style="">

          <el-pagination v-if="total>5"  style="margin: 0 auto;text-align: center;" :page-size="pageSize" :current-page="currentPage" layout="total,prev, pager, next" :total="total"  @current-change="handleCurrentChange" />
            </div>
        <div style="text-align: center;margin-top: 20px;">
            <el-button type="primary" round style="background-color: #76C8F6;border: none;margin-left: 10px;" @click="cancellation">确定</el-button>
        </div>
        
        </el-dialog>

        <!-- 关联系统 -->
        <el-dialog v-model="dialogVisible2" title="关联系统" width="20%" :before-close="handleClose2">
            <p style="margin-top: 10px;">
                <span style="font-size: 14px;;">请选择关联系统：</span>
                <el-select v-model="formInline.domains[domainsIndex].associatedSystemValue" @change="associatedSystemChange" class="m-2" placeholder="请选择" size="small">
                    <el-option v-for="item in associatedSystem" :key="item.id" :label="item.systemName" :value="item.id" :disabled="item.status == 1?false:true" />
                </el-select>
            </p>
            <p style="margin-top: 10px;">
                <span style="font-size: 14px;;">请选择直接访问模块：</span>
                <el-select v-model="formInline.domains[domainsIndex].associatedModuleValue" class="m-2" placeholder="请选择" size="small">
                    <el-option v-for="item in associatedModel" :key="item.id" :label="item.moduleName" :value="item.id" />
                </el-select>
            </p>
            <template #footer>
                <div class="dialog-footer" style="text-align: center;">
                    <el-button @click="dialogVisible2 = false">取消</el-button>
                    <el-button style="background-color: #76C8F6;border: none;" type="primary"
                        @click="dialogVisible2 = false">
                        确定
                    </el-button>
                </div>
            </template>
        </el-dialog>

        <el-dialog v-model="dialogVisible3" title="关联课程" width="40%" :before-close="handleClose3">
            <el-form :inline="true" class="demo-form-inline" style="margin-left: 20px;">
                <div v-for="(item, index) in domains3" :key="index">
                    <el-form-item label="关联课程">
                        <el-select v-model="item.region" placeholder="请选择关联课程" clearable>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="关联章">
                        <el-select v-model="item.region" placeholder="请选择关联章节" clearable>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="关联节">
                        <el-select v-model="item.region" placeholder="请选择关联节" clearable>
                        </el-select>
                    </el-form-item>
                    <el-button type="primary" round style="background-color: #76C8F6;border: none;"
                        @click="delete3Click(index)">X</el-button>
                </div>

                <el-button type="primary" round style="background-color: #76C8F6;border: none;"
                    @click="addFrom3">新增</el-button>
            </el-form>

            <template #footer>
                <div class="dialog-footer" style="text-align: center;">
                    <el-button style="background-color: #76C8F6;border: none;" type="primary"
                        @click="dialogVisible3 = false">
                        确定
                    </el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import { reactive, toRefs, onMounted, onUnmounted, computed, watch, ref, } from "vue";
import { useRouter,useRoute } from "vue-router";
import { useStore } from "vuex";
import { getStorage, setStorage } from "@/js/common";
import { backUrl } from "@/js/util"
import * as echarts from 'echarts';
import { baseUrl } from '@/js/util.js'
import {get_select_stageList,get_select_majorList,get_select_industryList} from '@/js/homeListApi'
import { DeleteFiles,f_get_digitalTrainingData,f_get_trainingExercisesData } from "@/js/courseManagementApi"
import {get_select_trainingSystem,get_select_trainingSystemModule,get_select_questionList,f_update_systemExperience,f_update_digitalTraining} from '@/js/teachingSquare'
import { get_topicCount,f_intsert_generateCoursePreviewPapers,f_get_systemExperienceExercises,f_update_replaceQuestions} from '@/js/classManagement'
import { ElMessage, ElMessageBox } from "element-plus";
export default {
    setup() {
        const router = useRouter();
        const route = useRoute();
        const store = useStore();
        const formLabel = ref(null);
        const rulesexercisesRef = ref(null);
        const validatestNum = (rule,value,callback)=>{
      /*console.log(rule);
      console.log(value);
      console.log(callback);*/
      if (!value){
        callback(new Error('请输入试题数量'));
      }
      //使用正则表达式进行验证是否为数字
      if (/\D/g.test(value)){
        callback(new Error('请输入正确格式'));
      }
      callback()
    };
        const state = reactive({
            examinationPaperId:'',
            oldId:'',
            questionsIsoder:'',
            formLabelAlign:{
                stageValue:'',
                digitalTraining:'',
                releaseDate:'',
                copyright:'',
                applicableMajors:'',
                Applications:'',
            },
            formLabelAlignrules:{
                stageValue:[ { required: true, message: '请选择', trigger: 'change' }],
                digitalTraining:[ { required: true, message: '请输入', trigger: 'blur' }],
                releaseDate:[ { required: true, message: '请选择', trigger: 'change' }],
                copyright:[{ required: true, message: '请输入', trigger: 'blur' }],
                applicableMajors:[{ required: true, message: '请选择', trigger: 'change' }],
                Applications:[{ required: true, message: '请选择', trigger: 'change' }],
            },
            value: '',
            value1: '',
            baseUrl:baseUrl,
            headers: {
                token: getStorage('ftoken'),
                operate: true
            },
            dialogVisible: false,
            dialogVisible2: false,
            dialogVisible3: false,
            replaceShow:false,
            stageValue:'',
            systemName:'',
            digitalTraining:'',
            questionsRow:{},
            examPreview:false,
            releaseDate:'',
            associatedModel:[],
            associatedSystem:[],
            systemOption:[],
            moduleOption:[],
            questionsArray:[],
            xitongValue:'',
            systemOption:[],
            moduleValue:'',
            moduleOption:[],
            testQuestionId:'',
            nanduValue:'',
            isAnswer:true,
            currentPage: 1,
            pageSize: 5,
            total:0,
            upDataName:'',
            stemData:'',
            tixingOptions: [
                {
                value: 1,
                label: "单选",
                },
                {
                value: 2,
                label: "多选",
                },
                {
                value: 3,
                label: "填空",
                },
                {
                value: 4,
                label: "判断",
                },
            ],
            nanduOptions: [
                {
                value: 1,
                label: "简单",
                },
                {
                value: 2,
                label: "中等",
                },
                {
                value: 3,
                label: "困难",
                },
            ],
            copyright:'',
            applicableMajors:'',
            Applications:'',
            domainsIndex:0,
            selectvalue: '',
            selectvalue2: '',
            danxuanList:[],
            duoxuanList:[],
            tiankongList:[],
            panduanList:[],
            options: [],
            labelPosition: 'right',
            radio1: '1',
            imgUrlId:[],
            imgUrl:'',
            examinationPaper:[],
            questionsList:[],
            coverPictureId:'',
            questionsIndex:'',
            titleName:'',
            formInline:{
                domains:[
                    {
                       joint:'',
                       exercisesnName:'',
                       radio1:"1",
                       associatedSystemValue:'',
                       associatedModuleValue:'',
                       children:[
                        {   
                            childrenShow:false,
                            textarea2:'',
                            textarea3:'',
                            textarea4:'',
                        }
                       ],
                       domains2:[
                        {
                            system:'',
                            module:'',
                            tixing:'',
                            nandu:'',
                            stNum:'',
                            numTest:0,
                        },
                    ],
                    questionsList:[],
                    }
                ],
               
                
            },
            rulesexercises:{
                exercisesnName:[{ required: true,message:'请输入习题名称',trigger: 'blur' }],
                system:[{ required: true,message:'请选择系统名称',trigger: 'change' }],
                module:[{ required: true,message:'请选择模块',trigger: 'change' }],
                tixing:[{ required: true,message:'请选择题型',trigger: 'change' }],
                nandu:[{ required: true,message:'请选择难度',trigger: 'change' }],
                stNum:[{ validator:validatestNum, trigger: 'blur' }],
            },
           
            tableData: [
                
            ],
            input: '',
            stageData:[],//所属阶段
            majorData:[],//适用专业
            industryData:[],//行业
        })
        onMounted(() => {
            methods.stageList();
            methods.majorList();
            methods.industryList()
            methods.get_select_trainingSystem();
            console.log(route.query.title);
            if (route.query.title == '编辑') {
                methods.f_get_digitalTrainingData();
                
            }
        })
        const methods = {
            stNumInput(item){
                console.log(item);
                if (item.stNum > item.numTest) {
                    ElMessage({
                                    message: "试题数量不能超过题库数量",
                                    type: "error",
                                    showClose: true,
                                    });
                     item.stNum = 0;
                }
                state.titleName = '修改';
            },
            insterClick(){
                if (state.formInline.domains[state.domainsIndex].radio1 == 2) {
                    if (state.titleName == '修改') {
                    return ElMessage({
                                    message: "请重新生成试卷",
                                    type: "info",
                                    showClose: true,
                                    });
                }
                }
                
                rulesexercisesRef.value.validate((valid) => {
                    if (valid) {
                        state.dialogVisible = false;
                    }
                })
                
            },
            // 编辑回填
            f_get_digitalTrainingData(){
                let params = {
                    digitalTrainingId:route.query.rowId
                }
                f_get_digitalTrainingData(params).then(res=>{
                    if (res.data.code == 1) {
                        state.titleName = route.query.title;
                        state.coverPictureId = res.data.data.coverPictureId;
                        state.imgUrl = res.data.data.coverPic.filePath;
                        state.formLabelAlign.stageValue = res.data.data.stageId;
                        state.formLabelAlign.digitalTraining = res.data.data.trainingName;
                        state.formLabelAlign.releaseDate = res.data.data.publishTime;
                        state.formLabelAlign.copyright = res.data.data.producer;
                        state.formLabelAlign.applicableMajors = res.data.data.majorId;
                        state.formLabelAlign.Applications = res.data.data.industryId;
                        state.formLabelAlign.id = res.data.data.id;
                        state.formInline.domains = [];
                        res.data.data.dtKnowledgePointList.forEach((element,index) => {
                            state.formInline.domains.push({
                                joint:'',
                                associatedSystemValue:'',
                                associatedModuleValue:'',
                                id:'',
                                children:[{textarea2:'',textarea3:'',textarea4:'',}]
                            })
                            // if (element.trainingSystem) {
                            //      state.formInline.domains.push({
                            //     joint:element.knowledgeName,
                            //     associatedSystemValue:element.trainingSystem.trainingSystemId,
                            //     associatedModuleValue:element.trainingSystem.trainingSystemModuleId,
                            //     id: element.id,
                            // })
                            // }
                            if (element.trainingSystem) {
                                 state.formInline.domains[index].associatedSystemValue = element.trainingSystem.trainingSystemId;
                            state.formInline.domains[index].associatedModuleValue = element.trainingSystem.trainingSystemModuleId;
                            }
                            state.formInline.domains[index].joint = element.knowledgeName;
                           
                            state.formInline.domains[index].children[0].textarea2 = element.trainingTask;
                            state.formInline.domains[index].children[0].textarea3 = element.trainingProcess;
                            state.formInline.domains[index].children[0].textarea4 = element.trainingValue;
                            state.formInline.domains[index].id = element.id;
                            methods.get_select_trainingSystemModule(state.formInline.domains[index].associatedSystemValue);
                            methods.f_get_trainingExercisesData(state.formInline.domains[index].id,index);
                        });
                       
                    }
                })
            },
            // 编辑回填作业数据
            f_get_trainingExercisesData(dataId,domainsIndex){
                state.domainsIndex = domainsIndex;
                let params = {
                    trainingType:1,
                    dataId:dataId
                }
                f_get_trainingExercisesData(params).then(res=>{
                    if (res.data.code == 1) {
                        state.formInline.domains[domainsIndex].exercisesnName = res.data.data.trainingExercises.exercisesnName;
                        state.formInline.domains[domainsIndex].trainingExercisesid = res.data.data.trainingExercises.id;
                        state.formInline.domains[domainsIndex].radio1 = res.data.data.trainingExercises.generativeRule.toString();
                       
                        if (res.data.data.examinationPaper) {
                            state.formInline.domains[domainsIndex].questionsList = res.data.data.examinationPaper;
                            state.examinationPaperId = res.data.data.examinationPaper.id;
                        }
                        
                        if (res.data.data.examinationPaper) {
                            state.questionsArray = state.formInline.domains[domainsIndex].questionsList.questions;
                            state.examinationPaper = res.data.data.examinationPaper;
                        }
                        
                               state.questionsList = [];
                               state.formInline.domains[domainsIndex].questionsList = [];
                               state.danxuanList = [];
                               state.duoxuanList = [];
                               state.tiankongList = [];
                               state.panduanList = [];
                               state.questionsArray.forEach((element) => {
                                  
                                    state.formInline.domains[domainsIndex].questionsList.push(element);
                                   
                               });
                               // setStorage("questionsList", state.questionsList);
                               state.formInline.domains[domainsIndex].questionsList.forEach(element => {
                                console.log(element);
                                element.forEach(element2 => {
                                    if (element2.topicType == 1) {
                                        element2.answers = element2.examinationPaperAnswers;
                                        delete element2.examinationPaperAnswers;
                                       state.danxuanList.push(element2);
                                       state.danxuanList.forEach(element => {
                                            element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                                        });
                                       console.log(state.danxuanList);
                                   }else if(element2.topicType == 2){
                                    element2.answers = element2.examinationPaperAnswers;
                                    delete element2.examinationPaperAnswers;
                                       state.duoxuanList.push(element2);
                                       state.duoxuanList.forEach(element => {
                                            element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                                        });
                                   }else if(element2.topicType == 3){
                                    element2.answers = element2.examinationPaperAnswers;
                                    delete element2.examinationPaperAnswers;
                                       state.tiankongList.push(element2)
                                       state.tiankongList.forEach(element => {
                                            element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                                        });
                                   }else{
                                    element2.answers = element2.examinationPaperAnswers;
                                    delete element2.examinationPaperAnswers;
                                       state.panduanList.push(element2)
                                       state.panduanList.forEach(element => {
                                            element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                                        });
                                       console.log(state.panduanList);
                                   }
                                });
                                       
                                   
                                   
                               });
                               state.formInline.domains[domainsIndex].questionsList = [];
                            state.formInline.domains[domainsIndex].questionsList =  state.formInline.domains[domainsIndex].questionsList.concat(state.danxuanList,state.duoxuanList,state.tiankongList,state.panduanList);
                               console.log(state.formInline.domains[domainsIndex].questionsList);
                        
                         console.log(state.formInline.domains[domainsIndex].questionsList);
                        state.formInline.domains[domainsIndex].domains2 = [];
                        res.data.data.trainingGenerativeRuleList.forEach((element,index) => {
                           
                                 state.formInline.domains[domainsIndex].domains2.push({
                                system:element.trainingSystemId,
                                module:element.trainingSystemModuleId,
                                tixing:element.topicType,
                                nandu:element.topicDifficulty,
                                stNum:element.topicCount,
                                id:element.id,
                            })
                            
                           
                            // state.formInline.domains[domainsIndex].domains2[index].system = element.trainingSystemId;
                            // state.formInline.domains[domainsIndex].domains2[index].module = element.trainingSystemModuleId;
                            // state.formInline.domains[domainsIndex].domains2[index].tixing = element.topicType;
                            // state.formInline.domains[domainsIndex].domains2[index].nandu = element.topicDifficulty;
                            // state.formInline.domains[domainsIndex].domains2[index].stNum = element.topicCount;
                            // state.formInline.domains[domainsIndex].domains2[index].id = element.id;
                            methods.get_topicCount(
                            state.formInline.domains[domainsIndex].domains2[index].tixing,
                            state.formInline.domains[domainsIndex].domains2[index].nandu,
                            state.formInline.domains[domainsIndex].domains2[index].system,
                            state.formInline.domains[domainsIndex].domains2[index].module,
                            domainsIndex,   
                            index,
                        );
                        });
                    }
                })
            },
            // 保存
            cligocenterCUN(){
                
                formLabel.value.validate((valid) => {
                    if (valid) {
                        if (state.coverPictureId == '') {
                            return  ElMessage({
                                    type: 'info',
                                    message: '请上传封面',
                                    })
                        }
                        const hasEmptyName = state.formInline.domains.some((obj) => obj.joint == '');
                        if (hasEmptyName) {
                            return ElMessage({
                                    type: 'info',
                                    message: '请输入节名称',
                                    })
                        }
                        const exercisesnName = state.formInline.domains.some((obj) => obj.exercisesnName == '');
                        if (exercisesnName) {
                            return ElMessage({
                                    type: 'info',
                                    message: '请配置习题',
                                    })
                        }
                        const associatedModuleValue = state.formInline.domains.some((obj) => obj.associatedModuleValue == '');
                        console.log(associatedModuleValue);
                        if (associatedModuleValue) {
                            return ElMessage({
                                    type: 'info',
                                    message: '请关联系统',
                                    })
                        }
                        const textarea2 = state.formInline.domains.some((obj) => obj.children[0].textarea2 == '');
                        if (textarea2) {
                            return ElMessage({
                                    type: 'info',
                                    message: '请填写实训任务',
                                    })
                        }
                        const textarea3 = state.formInline.domains.some((obj) => obj.children[0].textarea3 == '');
                        if (textarea3) {
                            return ElMessage({
                                    type: 'info',
                                    message: '请填写实训步骤',
                                    })
                        }
                        const textarea4 = state.formInline.domains.some((obj) => obj.children[0].textarea4 == '');
                        if (textarea4) {
                            return ElMessage({
                                    type: 'info',
                                    message: '请填写实训价值',
                                    })
                        }
                       
                let _dtKnowledgePointList = [];
                let trainingGenerativeRules = [];
                state.formInline.domains.forEach((element,index) => {
                    _dtKnowledgePointList.push({
                        knowledgeName:element.joint,
                        trainingTask:element.children[0].textarea2,
                        trainingProcess:element.children[0].textarea3,
                        trainingValue:element.children[0].textarea4,
                        trainingSystemId:element.associatedSystemValue,
                        trainingSystemModuleId:element.associatedModuleValue,
                        isOrder:index,
                        id:element.id,
                        i_trainingExercises:{
                            trainingGenerativeRules:[]
                        }
                    })
                        _dtKnowledgePointList[index].i_trainingExercises.questions =element.radio1 == '1'?[] : element.questionsList;
                        // _dtKnowledgePointList[index].i_trainingExercises.trainingType = 2;//2023.10.31 trainingType后端确认这个字段不上传
                        _dtKnowledgePointList[index].i_trainingExercises.exercisesnName = element.exercisesnName,
                         _dtKnowledgePointList[index].i_trainingExercises.generativeRule = element.radio1,
                         _dtKnowledgePointList[index].i_trainingExercises.id = element.trainingExercisesid,
                        // 试题
                        console.log(element);
                        element.domains2.forEach((element2,index2) => {
                            _dtKnowledgePointList[index].i_trainingExercises.trainingGenerativeRules.push({
                                trainingSystemId:element2.system,
                                trainingSystemModuleId:element2.module,
                                topicType:element2.tixing,
                                topicDifficulty:element2.nandu,
                                topicCount:element2.stNum,
                                id:element2.id
                            })
                            
                        });
                        // _dtKnowledgePointList[index].i_trainingExercises.trainingGenerativeRules = trainingGenerativeRules;
                });
                console.log(_dtKnowledgePointList);
                let params = {
                    id:state.formLabelAlign.id,
                    userId:getStorage('userId'),
                    coverPictureId:state.coverPictureId,
                    stageId:state.formLabelAlign.stageValue,
                    trainingName:state.formLabelAlign.digitalTraining,
                    publishTime:state.formLabelAlign.releaseDate,
                    producer:state.formLabelAlign.copyright,
                    majorId:state.formLabelAlign.applicableMajors,
                    industryId:state.formLabelAlign.Applications,
                    _dtKnowledgePointList:_dtKnowledgePointList
                }
                
                f_update_digitalTraining(params).then(res=>{
                    if (res.data.code == 1) {
                        ElMessage({
                            type: 'success',
                            message: '成功',
                            })
                            router.go(-1);
                        }else{
                            ElMessage({
                            type: 'error',
                            message: res.data.message,
                            })
                        }
                })
                    }
                })
                
            },
            // 关联系统的change事件
            associatedSystemChange(){
                state.formInline.domains[state.domainsIndex].associatedModuleValue = '';
                methods.get_select_trainingSystemModule(state.formInline.domains[state.domainsIndex].associatedSystemValue);
            },
                            // 单选替换试题
        duanxuanQuestions(item,index){
            console.log(item,index);
            state.replaceShow = true;
            state.topicType = item.topicType;
            state.testQuestionId = item.testQuestionId;
            state.questionsIndex = index;
            state.oldId = item.id;
            state.questionsIsoder = index+1;
            state.xitongValue = state.systemOption[0].id;
            state.moduleValue = state.moduleOption[0].id;
            state.nanduValue = state.nanduOptions[0].value;
            methods.get_select_questionList();
        },
        // 多选替换试题
        duoxuanQuestions(item,index){
            state.replaceShow = true;
            state.topicType = item.topicType;
            state.testQuestionId = item.testQuestionId;
            state.questionsIndex = index;
            state.oldId = item.id;
            state.questionsIsoder = index+1;
            state.xitongValue = state.systemOption[0].id;
            state.moduleValue = state.moduleOption[0].id;
            state.nanduValue = state.nanduOptions[0].value;
            methods.get_select_questionList();
        },
        // 填空替换试题
        tiankongQuestions(item,index){
            state.replaceShow = true;
            state.topicType = item.topicType;
            state.testQuestionId = item.testQuestionId;
            state.questionsIndex = index;
            state.oldId = item.id;
            state.questionsIsoder = index+1;
            state.xitongValue = state.systemOption[0].id;
            state.moduleValue = state.moduleOption[0].id;
            state.nanduValue = state.nanduOptions[0].value;
            methods.get_select_questionList();
        },
        // 判断替换试题
        panduanQuestions(item,index){
            state.replaceShow = true;
            state.topicType = item.topicType;
            state.testQuestionId = item.testQuestionId;
            state.questionsIndex = index;
            state.oldId = item.id;
            state.questionsIsoder = index+1;
            state.xitongValue = state.systemOption[0].id;
            state.moduleValue = state.moduleOption[0].id;
            state.nanduValue = state.nanduOptions[0].value;
            methods.get_select_questionList();
        },
        systemChange2(){
            methods.get_select_trainingSystemModule(state.xitongValue)
        },
        onSubmit(){
            state.currentPage = 1;
            methods.get_select_questionList();
        },
        handleCurrentChange(val){
            state.currentPage = val;
            methods.get_select_questionList();
        },
        // 获取试题列表
        get_select_questionList(){
            let params = {
                trainingSystemId:state.xitongValue,
                trainingSystemModuleId:state.moduleValue,
                questionBankType:2,
                topicDifficulty:state.nanduValue,
                pageIndex:state.currentPage,
                pageSize:state.pageSize,
                content:state.stemData,
                topicType:state.topicType,
                userName:state.upDataName,
            }
            get_select_questionList(params).then(res=>{
                if (res.data.code == 1) {
                    state.tableData = res.data.data.list;
                    state.total = res.data.data.totalCount.Value;
                    if (state.tableData.length>0) {
                        state.tableData.forEach(element => {
                        element.answers.forEach(item => {
                            if (item.isAnswer) {
                                element.correctAnswer = item.content;
                            }
                        });
                    });
                    }
                    
                }
            })
        },
            // 操作选择
            deselect(row){
               
                state.tableData.forEach(element => {
                    element.select = 0;
                });
                row.select = 1;
                state.questionsRow = row;
            },                          // 确认
      cancellation() {
        console.log(state.questionsRow,route.query.title);
        if (route.query.title == '编辑') {
            let params = {
                examinationPaperId:state.examinationPaperId,
                oldQuestionsId:state.oldId,
                newQuestionsId:state.questionsRow.oldId,
                isOrder:state.questionsIsoder
            }
            f_update_replaceQuestions(params).then(res=>{
                if (res.data.code == 1) {
                    const item = res.data.data;
                    if (item.topicType == 1) {
                state.danxuanList[state.questionsIndex].id = item.id;
                state.danxuanList[state.questionsIndex].testQuestionId = item.testQuestionId;
                state.danxuanList[state.questionsIndex].testQuestionContent = item.testQuestionContent;
                state.danxuanList[state.questionsIndex].answers = item.answers;
                state.danxuanList[state.questionsIndex].testQuestionAnalysis = item.testQuestionAnalysis;
                state.danxuanList.forEach(element => {
                    element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                });
            }else if(item.topicType == 2){
                state.duoxuanList[state.questionsIndex].id = item.id;
                state.duoxuanList[state.questionsIndex].testQuestionId = item.testQuestionId;
                state.duoxuanList[state.questionsIndex].testQuestionContent = item.testQuestionContent;
                state.duoxuanList[state.questionsIndex].answers = item.answers;
                state.duoxuanList[state.questionsIndex].testQuestionAnalysis = item.testQuestionAnalysis;
                state.duoxuanList.forEach(element => {
                    element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                });
            }else if(item.topicType == 3){
                state.tiankongList[state.questionsIndex].id = item.id;
                state.tiankongList[state.questionsIndex].testQuestionId = item.testQuestionId;
                state.tiankongList[state.questionsIndex].testQuestionContent = item.testQuestionContent;
                state.tiankongList[state.questionsIndex].answers = item.answers;
                state.tiankongList[state.questionsIndex].testQuestionAnalysis = item.testQuestionAnalysis;
                state.tiankongList.forEach(element => {
                    element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                });
            }else if(item.topicType == 4){
                state.panduanList[state.questionsIndex].id = item.id;
                state.panduanList[state.questionsIndex].testQuestionId = item.testQuestionId;
                state.panduanList[state.questionsIndex].testQuestionContent = item.testQuestionContent;
                state.panduanList[state.questionsIndex].answers = item.answers;
                state.panduanList[state.questionsIndex].testQuestionAnalysis = item.testQuestionAnalysis;
                state.panduanList.forEach(element => {
                    element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                });
            }
                }else{
                    if (state.questionsRow.topicType == 1) {
                state.danxuanList[state.questionsIndex].id = state.questionsRow.newId;
                state.danxuanList[state.questionsIndex].testQuestionId = state.questionsRow.oldId;
                state.danxuanList[state.questionsIndex].testQuestionContent = state.questionsRow.testQuestionContent;
                state.danxuanList[state.questionsIndex].answers = state.questionsRow.answers;
                state.danxuanList[state.questionsIndex].testQuestionAnalysis = state.questionsRow.testQuestionAnalysis;
                state.danxuanList.forEach(element => {
                    element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                });
            }else if(state.questionsRow.topicType == 2){
                state.duoxuanList[state.questionsIndex].id = state.questionsRow.newId;
                state.duoxuanList[state.questionsIndex].testQuestionId = state.questionsRow.oldId;
                state.duoxuanList[state.questionsIndex].testQuestionContent = state.questionsRow.testQuestionContent;
                state.duoxuanList[state.questionsIndex].answers = state.questionsRow.answers;
                state.duoxuanList[state.questionsIndex].testQuestionAnalysis = state.questionsRow.testQuestionAnalysis;
                state.duoxuanList.forEach(element => {
                    element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                });
            }else if(state.questionsRow.topicType == 3){
                state.tiankongList[state.questionsIndex].id = state.questionsRow.newId;
                state.tiankongList[state.questionsIndex].testQuestionId = state.questionsRow.oldId;
                state.tiankongList[state.questionsIndex].testQuestionContent = state.questionsRow.testQuestionContent;
                state.tiankongList[state.questionsIndex].answers = state.questionsRow.answers;
                state.tiankongList[state.questionsIndex].testQuestionAnalysis = state.questionsRow.testQuestionAnalysis;
                state.tiankongList.forEach(element => {
                    element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                });
            }else if(state.questionsRow.topicType == 4){
                state.panduanList[state.questionsIndex].id = state.questionsRow.newId;
                state.panduanList[state.questionsIndex].testQuestionId = state.questionsRow.oldId;
                state.panduanList[state.questionsIndex].testQuestionContent = state.questionsRow.testQuestionContent;
                state.panduanList[state.questionsIndex].answers = state.questionsRow.answers;
                state.panduanList[state.questionsIndex].testQuestionAnalysis = state.questionsRow.testQuestionAnalysis;
                state.panduanList.forEach(element => {
                    element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                });
            }
                }
                state.replaceShow = false;
            })
        }else{
            let obj = {};
        if (Object.keys(state.questionsRow).length>0) {
          if (state.questionsRow.topicType == 1) {
                state.danxuanList[state.questionsIndex].id = state.questionsRow.newId;
                state.danxuanList[state.questionsIndex].testQuestionId = state.questionsRow.oldId;
                state.danxuanList[state.questionsIndex].testQuestionContent = state.questionsRow.testQuestionContent;
                state.danxuanList[state.questionsIndex].answers = state.questionsRow.answers;
                state.danxuanList[state.questionsIndex].testQuestionAnalysis = state.questionsRow.testQuestionAnalysis;
                state.danxuanList.forEach(element => {
                    element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                });
            }else if(state.questionsRow.topicType == 2){
                state.duoxuanList[state.questionsIndex].id = state.questionsRow.newId;
                state.duoxuanList[state.questionsIndex].testQuestionId = state.questionsRow.oldId;
                state.duoxuanList[state.questionsIndex].testQuestionContent = state.questionsRow.testQuestionContent;
                state.duoxuanList[state.questionsIndex].answers = state.questionsRow.answers;
                state.duoxuanList[state.questionsIndex].testQuestionAnalysis = state.questionsRow.testQuestionAnalysis;
                state.duoxuanList.forEach(element => {
                    element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                });
            }else if(state.questionsRow.topicType == 3){
                state.tiankongList[state.questionsIndex].id = state.questionsRow.newId;
                state.tiankongList[state.questionsIndex].testQuestionId = state.questionsRow.oldId;
                state.tiankongList[state.questionsIndex].testQuestionContent = state.questionsRow.testQuestionContent;
                state.tiankongList[state.questionsIndex].answers = state.questionsRow.answers;
                state.tiankongList[state.questionsIndex].testQuestionAnalysis = state.questionsRow.testQuestionAnalysis;
                state.tiankongList.forEach(element => {
                    element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                });
            }else if(state.questionsRow.topicType == 4){
                state.panduanList[state.questionsIndex].id = state.questionsRow.newId;
                state.panduanList[state.questionsIndex].testQuestionId = state.questionsRow.oldId;
                state.panduanList[state.questionsIndex].testQuestionContent = state.questionsRow.testQuestionContent;
                state.panduanList[state.questionsIndex].answers = state.questionsRow.answers;
                state.panduanList[state.questionsIndex].testQuestionAnalysis = state.questionsRow.testQuestionAnalysis;
                state.panduanList.forEach(element => {
                    element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                });
            }
        }else{
            console.log(1111);
        }
        state.replaceShow = false;
        }


      },
             // 获取当前符合条件的试题数
             get_topicCount(
                        topicType,
                        topicDifficulty,
                        courseChapterId,
                        courseSectionId,
                        domainsIndex,
                        index
                        
                    ) {
                        if (
                        topicType &&
                        topicDifficulty &&
                        courseChapterId &&
                        courseSectionId
                        ) {
                        let params = {
                            topicType: topicType,
                            topicDifficulty: topicDifficulty,
                            questionBankType: 2,
                            trainingSystemId: courseChapterId,
                            trainingSystemModuleId: courseSectionId,
                        };
                        get_topicCount(params).then((res) => {
                            if (res.data.code == 1) {
                            state.formInline.domains[domainsIndex].domains2[index].numTest = res.data.data;
                            }
                        });
                        }
                    },
             // 获取系统下拉数据
             get_select_trainingSystem(){
            get_select_trainingSystem().then(res=>{
                if (res.data.code == 1) {
                    state.platform = res.data.data;
                    state.systemOption = res.data.data;
                    state.associatedSystem = res.data.data;
                }
                
            })
        },
        // 系统
        systemChange(index){
            state.formInline.domains[state.domainsIndex].domains2[index].module = '';
            console.log(state.formInline.domains[state.domainsIndex].domains2[index]);
            methods.get_select_trainingSystemModule(state.formInline.domains[state.domainsIndex].domains2[index].system);
            methods.get_topicCount(
                            state.formInline.domains[state.domainsIndex].domains2[index].tixing,
                            state.formInline.domains[state.domainsIndex].domains2[index].nandu,
                            state.formInline.domains[state.domainsIndex].domains2[index].system,
                            state.formInline.domains[state.domainsIndex].domains2[index].module, 
                            state.domainsIndex,  
                            index
                        );
                        state.titleName = '修改';
        },
        visibleChange(index){
            methods.get_select_trainingSystemModule(state.formInline.domains[state.domainsIndex].domains2[index].system);
        },
        // 模块
        moduleChange(index){
            methods.get_topicCount(
                            state.formInline.domains[state.domainsIndex].domains2[index].tixing,
                            state.formInline.domains[state.domainsIndex].domains2[index].nandu,
                            state.formInline.domains[state.domainsIndex].domains2[index].system,
                            state.formInline.domains[state.domainsIndex].domains2[index].module,  
                            state.domainsIndex, 
                            index
                        );
                        state.titleName = '修改';
        },
        // 题型
        tixingChange(index){
            methods.get_topicCount(
                            state.formInline.domains[state.domainsIndex].domains2[index].tixing,
                            state.formInline.domains[state.domainsIndex].domains2[index].nandu,
                            state.formInline.domains[state.domainsIndex].domains2[index].system,
                            state.formInline.domains[state.domainsIndex].domains2[index].module,   
                            state.domainsIndex,
                            index
                        );
                        state.titleName = '修改';
        },
        // 难度
        nanduChange(index){
            methods.get_topicCount(
                            state.formInline.domains[state.domainsIndex].domains2[index].tixing,
                            state.formInline.domains[state.domainsIndex].domains2[index].nandu,
                            state.formInline.domains[state.domainsIndex].domains2[index].system,
                            state.formInline.domains[state.domainsIndex].domains2[index].module,  
                            state.domainsIndex, 
                            index
                        );
                        state.titleName = '修改';
        },
         // 获取系统下模块数据
         get_select_trainingSystemModule(event){
            console.log(event);
            let params = {
                trainingSystemId:event
            }
            get_select_trainingSystemModule(params).then(res=>{
                if (res.data.code == 1) {
                    state.moduleOption = res.data.data;
                    state.associatedModel = res.data.data;
                }
            })
        },
            beforeAvatarUpload() {

            },
            replaceShowClose(){
                state.replaceShow = false;
            },
            handleAvatarSuccess(res) {
                state.imgUrlId = [];
                state.imgUrl = res.data[0].filePath;
                state.imgUrlId.push(res.data[0].Id);
                state.coverPictureId = res.data[0].Id;
                console.log(state.coverPictureId);
            },
            deleteClickImg() {
                DeleteFiles(state.imgUrlId).then(res => {
                    if (res.data.code == 1) {
                        state.imgUrlId = [];
                        state.imgUrl = '',
                            state.coverPictureId = '';
                    }
                })

            },
            childrenShowClick(index) {
                state.formInline.domains[index].children[0].childrenShow = !state.formInline.domains[index].children[0].childrenShow;
            },
            peizhiClick(index,dataId) {
                state.domainsIndex = index;
                state.dialogVisible = true;
                if (dataId) {
                     methods.f_get_trainingExercisesData(dataId,index);
                }
               
                state.formInline.domains[state.domainsIndex].domains2.forEach(element => {
                       methods.get_select_trainingSystemModule(element.system)
                });
                
                
                
            },
            handleClose() {
                state.dialogVisible = false;
            },
            handleClose2() {
                state.dialogVisible2 = false;
            },
            handleClose3() {
                state.dialogVisible3 = false;
            },
            yulanClick() {
                state.examPreview = true;
                let kechengtArr = state.associatedSystem.filter((item) => {
                    return item.id === state.formInline.domains[state.domainsIndex].associatedSystemValue;
                  });
                  console.log(kechengtArr);
                  state.systemName = kechengtArr[0].systemName;
                methods.f_intsert_generateCoursePreviewPapers();
            },
            radioChange(event){
                console.log(event);
                if (event == 2) {
                    methods.f_intsert_generateCoursePreviewPapers();
                }
            },
            examPreviewClose(){
                state.examPreview = false;
            },
                        // 生成试卷
                        f_intsert_generateCoursePreviewPapers(){
                            console.log(state.domainsIndex);
               let arraylist = [];
                      
                            state.formInline.domains[state.domainsIndex].domains2.forEach((element) => {
                           arraylist.push({
                               trainingSystemId: element.system,
                               trainingSystemModuleId: element.module,
                               topicType: element.tixing,
                               topicDifficulty: element.nandu,
                               topicCount: element.stNum*1,
                           });
                           });
                       
                      
                       f_intsert_generateCoursePreviewPapers(arraylist).then(res=>{
                           if (res.data.code == 1) {
                            console.log(state.titleName);
                            
                            if (state.titleName == '编辑') {
                                state.questionsList = [];
                               state.formInline.domains[state.domainsIndex].questionsList = [];
                               state.danxuanList = [];
                               state.duoxuanList = [];
                               state.tiankongList = [];
                               state.panduanList = [];
                                if (state.examinationPaper) {
                                    state.questionsArray = state.examinationPaper.questions;
                                    state.questionsArray.forEach((element) => {
                                  
                                  state.formInline.domains[state.domainsIndex].questionsList.push(element);
                                 
                                 
                             });
                             state.formInline.domains[state.domainsIndex].questionsList.forEach(element => {
                                console.log(element);
                                element.forEach(element2 => {
                                    if (element2.topicType == 1) {
                                        if (element2.examinationPaperAnswers) {
                                             element2.answers = element2.examinationPaperAnswers;
                                        delete element2.examinationPaperAnswers;
                                        }
                                       
                                       state.danxuanList.push(element2);
                                       console.log(state.danxuanList);
                                   }else if(element2.topicType == 2){
                                    if (element2.examinationPaperAnswers) {
                                             element2.answers = element2.examinationPaperAnswers;
                                        delete element2.examinationPaperAnswers;
                                        }
                                       state.duoxuanList.push(element2);
                                   }else if(element2.topicType == 3){
                                    if (element2.examinationPaperAnswers) {
                                             element2.answers = element2.examinationPaperAnswers;
                                        delete element2.examinationPaperAnswers;
                                        }
                                       state.tiankongList.push(element2)
                                   }else{
                                    if (element2.examinationPaperAnswers) {
                                             element2.answers = element2.examinationPaperAnswers;
                                        delete element2.examinationPaperAnswers;
                                        }
                                       state.panduanList.push(element2)
                                       console.log(state.panduanList);
                                   }
                                });
                                       
                                   
                                   
                               });
                                }else{
                                    state.questionsArray = res.data.data;
                                    state.questionsArray.forEach((element) => {
                                   element.questions.forEach((item) => {
                                    state.formInline.domains[state.domainsIndex].questionsList.push(item);
                                   });
                               });
                               state.formInline.domains[state.domainsIndex].questionsList.forEach(element => {
                                console.log(element);
                                       if (element.topicType == 1) {
                                       state.danxuanList.push(element);
                                       console.log(state.danxuanList);
                                   }else if(element.topicType == 2){
                                       state.duoxuanList.push(element);
                                   }else if(element.topicType == 3){
                                       state.tiankongList.push(element)
                                   }else{
                                       state.panduanList.push(element)
                                       console.log(state.panduanList);
                                   }
                                   
                                   
                               });
                                }
                                
                              
                               console.log( state.formInline.domains[state.domainsIndex].questionsList);
                               // setStorage("questionsList", state.questionsList);
                              
                            }else{
                                state.questionsArray = res.data.data;
                               state.questionsList = [];
                               state.formInline.domains[state.domainsIndex].questionsList = [];
                               state.danxuanList = [];
                               state.duoxuanList = [];
                               state.tiankongList = [];
                               state.panduanList = [];
                               state.questionsArray.forEach((element) => {
                                   element.questions.forEach((item) => {
                                    state.formInline.domains[state.domainsIndex].questionsList.push(item);
                                   });
                               });
                               // setStorage("questionsList", state.questionsList);
                               state.formInline.domains[state.domainsIndex].questionsList.forEach(element => {
                                console.log(element);
                                       if (element.topicType == 1) {
                                       state.danxuanList.push(element);
                                       console.log(state.danxuanList);
                                   }else if(element.topicType == 2){
                                       state.duoxuanList.push(element);
                                   }else if(element.topicType == 3){
                                       state.tiankongList.push(element)
                                   }else{
                                       state.panduanList.push(element)
                                       console.log(state.panduanList);
                                   }
                                   
                                   
                               });
                            }
                               
                            //    state.examPreview = true;
                           }else{

                           }
                       })
           },
            // 确认
            confirmClick(){
                console.log(state.danxuanList);
                state.titleName = '';
                state.formInline.domains[state.domainsIndex].questionsList = [];
                state.formInline.domains[state.domainsIndex].questionsList =  state.formInline.domains[state.domainsIndex].questionsList.concat(state.danxuanList,state.duoxuanList,state.tiankongList,state.panduanList);
            state.examPreview = false;
            console.log(state.formInline.domains[state.domainsIndex].questionsList);
        },
            addModel() {
                state.formInline.domains.push(
                    {
                       joint:'',
                       exercisesnName:'',
                       radio1:"1",
                       children:[
                        {   
                            childrenShow:false,
                            textarea2:'',
                            textarea3:'',
                            textarea4:'',
                        }
                       ],
                       domains2:[
                        {
                            system:'',
                            module:'',
                            tixing:'',
                            nandu:'',
                            stNum:'',
                            numTest:0,
                        },
                    ],
                    questions:[]
                    }
            );
               
            },
            deleteClick(index) {
                state.formInline.domains.splice(index, 1)
            },
            addFrom() {
                state.formInline.domains[state.domainsIndex].domains2.push(
                    {
                        system:'',
                            module:'',
                            tixing:'',
                            nandu:'',
                            stNum:'',
                            numTest:0,
                    }
                )
               
            },
            addFrom3() {
                state.domains3.push({
                    user: '',
                    region: ''
                })
            },
            delete3Click(index) {
                console.log(index);
                state.domains3.splice(index, 1)
            },
            delete2Click(index) {
                state.formInline.domains[state.domainsIndex].domains2.splice(index, 1);
                state.titleName = '修改';
            },
            guanlian(index) {
                state.domainsIndex = index;
                state.dialogVisible2 = true;
            },
            kecheng() {
                state.dialogVisible3 = true;
            },
            blackClick() {
                router.push('/home/courseList')
            },
            //所属阶段
            stageList(){
                get_select_stageList().then(res=>{
                    const {status,data}=res
                    if(status == 200){
                        state.stageData = data.data
                    }
                })
            },
            //适用专业
            majorList(){
                get_select_majorList().then(res=>{
                    console.log(res,'res')
                    const {status,data}=res
                    if(status == 200){
                        state.majorData = data.data
                    }
                    
                })
            },
            //获取行业
            industryList(){
                get_select_industryList().then(res=>{
                    console.log(res,'res')
                    const {status,data}=res
                    if(status == 200){
                        state.industryData = data.data
                    }
                    
                })
            }
        }
       
        return {
            ...toRefs(state),
            ...methods,
            formLabel,
            rulesexercisesRef
        };
    }
}
</script>

<style  lang="scss" scoped>
#courseTeaching {
    height: 100%;
    position: relative;
    background: #F7F8FA;
    .upload-demo {
    width: 495px;
    height: 281px;
    border-radius: 4px;
    border: 1px solid #E1E1E1;
    float: left;
}
    .box {
        width: 66.5%;
        height: 100%;
        margin: 0 auto;

        .boxOne {
            widows: 100%;
            height: 300px;
            background: #fff;
            margin-top: 10px;
            padding: 10px;
            display: flex;
            justify-content: flex-start;
        }

        .boxTwo {
            width: 100%;
            background: #fff;
            margin-top: 10px;
        }
    }

}
</style>